import { selectContentSection, useAppSelector } from '@open-tender/cloud'
import { TPLSRewards as TPLSRewardsType } from '@open-tender/types'
import { ScrollableItems } from 'components'
import {
  TPLSRewards,
  TPLSRewardsContainer,
  TPLSRewardsReward,
  TPLSRewardsView,
} from './ThirdPartyLoyalty.styled'
import ThirdPartyLoyaltyReward from './ThirdPartyLoyaltyReward'
import AccountSection from 'components/pages/Account/AccountSection'

const ThirdPartyLoyaltyRewards = ({
  isAccount,
  rewards,
}: {
  isAccount: boolean
  rewards: TPLSRewardsType
}) => {
  const account = useAppSelector(selectContentSection('account'))
  const { title } = account?.rewards || {}

  if (isAccount && !rewards.length) return null

  return isAccount ? (
    <AccountSection>
      <ScrollableItems title={title} to="/rewards">
        {rewards.map((reward) => (
          <ThirdPartyLoyaltyReward key={reward.ext_id} reward={reward} />
        ))}
      </ScrollableItems>
    </AccountSection>
  ) : (
    <TPLSRewardsView>
      <TPLSRewards>
        <TPLSRewardsContainer>
          {rewards.length ? (
            rewards.map((reward) => (
              <TPLSRewardsReward key={reward.ext_id}>
                <ThirdPartyLoyaltyReward reward={reward} />
              </TPLSRewardsReward>
            ))
          ) : (
            <p>Looks like you don't have any rewards yet.</p>
          )}
        </TPLSRewardsContainer>
      </TPLSRewards>
    </TPLSRewardsView>
  )
}

export default ThirdPartyLoyaltyRewards
