import styled from '@emotion/styled'
import {
  setAddress,
  selectOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { GoogleMapsAutocomplete } from 'components/maps'
import { Navigation } from 'components/icons'

const MapsAutocompleteView = styled.div`
  position: fixed;
  z-index: 15;
  top: ${(props) => props.theme.layout.navHeight};
  right: 0;
  width: 76.8rem;
  // height: ${(props) => props.theme.layout.navHeight};
  height: 10rem;
  padding: 0 ${(props) => props.theme.layout.padding};
  background-color: ${(props) => props.theme.bgColors.primary};
  background-color: ${(props) => props.theme.bgColors.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
    top: ${(props) => props.theme.layout.navHeightMobile};
    // height: ${(props) => props.theme.layout.navHeightMobile};
    height: 9rem;
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
  }
`

const MapsAutocomplete = ({
  setCenter,
  maps,
  map,
  sessionToken,
  autocomplete,
}: {
  setCenter: ({ lat, lng }: { lat: number; lng: number }) => void
  maps?: typeof google.maps | null
  map?: google.maps.Map | null
  sessionToken?: google.maps.places.AutocompleteSessionToken | null
  autocomplete?: google.maps.places.AutocompleteService | null
}) => {
  const dispatch = useAppDispatch()
  const { address, serviceType } = useAppSelector(selectOrder)
  const formattedAddress = address ? address.formatted_address : ''
  const placeholder =
    serviceType === 'DELIVERY'
      ? 'enter a delivery address'
      : 'enter an address or zip code'

  if (!maps || !map || !sessionToken || !autocomplete) return null

  return (
    <MapsAutocompleteView>
      <GoogleMapsAutocomplete
        maps={maps}
        map={map}
        sessionToken={sessionToken}
        autocomplete={autocomplete}
        formattedAddress={formattedAddress || ''}
        setAddress={(address) => dispatch(setAddress(address))}
        setCenter={setCenter}
        icon={<Navigation size={14} strokeWidth={2} />}
        placeholder={placeholder}
      />
    </MapsAutocompleteView>
  )
}

export default MapsAutocomplete
