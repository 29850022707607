import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { ISOString, Order } from '@open-tender/types'
import {
  timezoneMap,
  isoToDateStr,
  makeOrderTypeName,
} from '@open-tender/utils'
import { Card } from 'components'

const OrderCardSimpleButton = styled.button`
  display: block;
  width: 100%;
  height: 100%;
  text-align: left;
`

// const OrderCardSimpleTag = styled.div`
//   position: absolute;
//   top: 1rem;
//   right: 1rem;

//   & > span {
//     padding: 0.3rem 1rem 0.4rem;
//   }
// `

interface OrderCardSimpleProps {
  order: Order
}

const OrderCardSimple = ({ order }: OrderCardSimpleProps) => {
  const navigate = useNavigate()
  const {
    order_id,
    service_type,
    order_type,
    revenue_center,
    requested_at,
    timezone,
    cart,
    // status,
    // order_prep,
  } = order
  // const { is_in_prep_queue = false } = order_prep || {}
  const orderTypeName = makeOrderTypeName(order_type, service_type)
  const tz = timezoneMap[timezone]
  const requestedAt = isoToDateStr(
    requested_at as ISOString,
    tz,
    'MMMM d, yyyy'
  )
  // const isUpcoming = isoToDate(requested_at as ISOString) > new Date()
  const sorted = [...cart].sort(
    (a, b) => parseFloat(b.price_total) - parseFloat(a.price_total)
  )
  const itemImages = sorted
    .map((i) =>
      i.images
        .filter((m) => m.type === 'SMALL_IMAGE' && m.url)
        .map((image) => ({ title: i.name, imageUrl: image.url }))
    )
    .flat()

  const bgStyle =
    itemImages.length > 0
      ? { backgroundImage: `url(${itemImages[0].imageUrl}` }
      : undefined

  const title = `${orderTypeName} from ${revenue_center.name}`
  const subtitle = `Order #${order_id} - ${requestedAt}`

  return (
    <OrderCardSimpleButton onClick={() => navigate(`/orders/${order_id}`)}>
      <Card
        bgStyle={bgStyle}
        title={title}
        subtitle={subtitle}
        // tag={
        //   <OrderCardSimpleTag>
        //     <OrderTag
        //       isUpcoming={isUpcoming}
        //       status={status}
        //       inQueue={is_in_prep_queue}
        //     />
        //   </OrderCardSimpleTag>
        // }
      />
    </OrderCardSimpleButton>
  )
}

export default OrderCardSimple
