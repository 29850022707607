import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { Body } from 'components'

const SeeMoreLinkView = styled.div<{ isHeader?: boolean }>`
  flex-grow: 0;
  flex-shrink: 0;
  a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    text-decoration: none;
    color: ${(props) => props.theme.links.dark.color};
    &:hover,
    &:active {
      color: ${(props) => props.theme.links.dark.hover};
      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        color: ${(props) => props.theme.links.dark.color};
      }
    }
  }
`

const SeeMoreLinkText = styled(Body)<{ isHeader?: boolean }>`
  display: block;
  line-height: 1;
  font-size: 1.8rem;
  transition: ${(props) => props.theme.links.transition};
  text-decoration: underline;
  color: ${(props) => props.theme.links.dark.color};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }

  a:hover &,
  a:active & {
    color: ${(props) => props.theme.links.dark.hover};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      color: ${(props) => props.theme.links.dark.color};
    }
  }
`

// const SeeMoreLinkArrow = styled.div`
//   margin: 0 0 0 0.3rem;
//   display: flex;
// `

const SeeMoreLink = ({
  text,
  to,
  isHeader = false,
}: {
  text?: string
  to: string
  isHeader?: boolean
}) => (
  <SeeMoreLinkView isHeader={isHeader}>
    <Link to={to}>
      <SeeMoreLinkText isHeader={isHeader}>{text}</SeeMoreLinkText>
      {/* <SeeMoreLinkArrow>
        <ArrowRight size={isMobile ? 16 : 18} />
      </SeeMoreLinkArrow> */}
    </Link>
  </SeeMoreLinkView>
)

export default SeeMoreLink
