import styled from '@emotion/styled'
import { BgImage, Body, Box } from 'components'

export const TPLSSummary = styled(Box)<{ isAccount: boolean }>`
  width: 100%;
  max-width: ${(props) => (!props.isAccount ? '64rem' : '100%')};
  padding: 2rem;
  margin: 0 auto;
`

export const TPLSSummaryContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TPLSSummaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3rem;
`

export const TPLSSummaryStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TPLSSummaryStatusSep = styled.div`
  min-height: 8.4rem;
  height: 100%;
  width: 0.2rem;
  background-color: ${(props) => props.theme.colors.primary};
  margin: 0 2rem 0 2.5rem;
`

export const TPLSSummaryPoints = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  // gap: 0.5rem;
`

export const TPLSSummaryPointsHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`

export const TPLSRewardsView = styled.div`
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }
`

export const TPLSRewards = styled.div`
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.25s forwards;
`

export const TPLSRewardsContainer = styled.div`
  margin: 0 -1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 -0.5rem;
  }
`

export const TPLSRewardsReward = styled.div`
  width: 33.33333%;
  padding: 0 1rem 2.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 50%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 50%;
    padding: 0 0.5rem 2.5rem;
  }
`

export const TPLSRewardView = styled(Box)`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 2rem 3rem 2rem 2rem;
`

export const TPLSRewardImage = styled(BgImage)`
  position: relative;
  width: 100%;
  padding: 37.5% 0;
  background-color: ${(props) => props.theme.bgColors.tertiary};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 33.33333% 0;
  }
`

export const TPLSRewardContent = styled.div`
  margin-top: 3rem;
  padding: 0;
`

export const TPLSRewardTitle = styled(Body)`
  font-weight: ${(props) => props.theme.boldWeight};
  line-height: 120%;
`

export const TPLSRewardDescription = styled(Body)`
  margin: 0.8rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0.5rem 0 0;
  }
`

export const TPLSRewardExipresAt = styled(Body)`
  font-weight: ${(props) => props.theme.boldWeight};
`

export const TPLSProgressView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const TPLSProgressContent = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.theme.layout.padding};
  margin: 0 auto;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: ${(props) => props.theme.layout.paddingMobile};
    margin: 0 auto;
  }

  & > div {
    width: 20rem;
    height: 20rem;
    margin: ${(props) => props.theme.layout.paddingMobile} 0;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 12rem;
      height: 12rem;
    }
  }

  & > p:first-of-type {
    font-size: ${(props) => props.theme.fonts.sizes.xBig};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fonts.sizes.big};
    }
  }

  & > p:last-of-type {
    font-size: ${(props) => props.theme.fonts.sizes.big};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fonts.sizes.small};
    }
  }
`

export const TPLSSignUpMessage = styled(Box)`
  padding: 1.5rem;
  margin: 0 0 ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.layout.paddingMobile};
    margin: 0 0 ${(props) => props.theme.layout.paddingMobile};
  }

  p + p {
    margin: 1rem 0 0;
  }
`
