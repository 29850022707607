import { ISOString, TPLSReward } from '@open-tender/types'
import { TPLSRewardExipresAt } from './ThirdPartyLoyalty.styled'
import { isoToDateStr } from '@open-tender/utils'
import { selectTimezone, useAppSelector } from '@open-tender/cloud'
import { Card, CardSubtitle } from 'components'

const ThirdPartyLoyaltyReward = ({ reward }: { reward: TPLSReward }) => {
  const tz = useAppSelector(selectTimezone)
  const { name, description, expires_at, image } = reward
  const bgStyle = image ? { backgroundImage: `url(${image}` } : undefined
  const expiresAt = expires_at
    ? isoToDateStr(expires_at as ISOString, tz, 'MMM d, yyyy')
    : null

  return (
    <Card bgStyle={bgStyle} title={name} subtitle={description}>
      {expiresAt && (
        <CardSubtitle as="p" size="xSmall">
          Expires <TPLSRewardExipresAt>{expiresAt}</TPLSRewardExipresAt>
        </CardSubtitle>
      )}
    </Card>
  )
}

export default ThirdPartyLoyaltyReward
