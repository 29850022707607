import { selectBrand, useAppSelector } from '@open-tender/cloud'
import AccountSection from './AccountSection'
import AccountLoyaltyProgram from './AccountLoyaltyProgram'
import ThanxLoyalty from '../Rewards/ThanxLoyalty'
import ThirdPartyLoyalty from 'components/ThirdPartyLoyalty'
import AccountPointsShop from './AccountPointsShop'

const AccountLoyalty = () => {
  const { has_loyalty, has_thanx, tpls } = useAppSelector(selectBrand) || {}
  const isSparkfly = tpls === 'SPARKFLY'

  return has_loyalty ? (
    <>
      <AccountSection>
        <AccountLoyaltyProgram />
      </AccountSection>
      <AccountPointsShop />
    </>
  ) : has_thanx ? (
    <AccountSection>
      <ThanxLoyalty isAccount={true} />
    </AccountSection>
  ) : tpls ? (
    <>
      <ThirdPartyLoyalty isAccount={true} />
      {!isSparkfly && <AccountPointsShop />}
    </>
  ) : null
}

export default AccountLoyalty
