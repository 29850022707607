import { ErrorInfo, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import {
  resetOrderType,
  resetCheckout,
  selectBrand,
  selectContent,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Button,
  ButtonLink,
  Content,
  Header,
  HeaderLogo,
  Main,
  PageContainer,
  PageContent,
  PageTitle,
} from 'components'

const ErrorReportView = styled.div`
  margin: ${(props) => props.theme.layout.padding} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.paddingMobile} 0;
  }
`

const ErrorReportDetails = styled.div`
  padding: 2rem;
  overflow-x: scroll;
  color: ${(props) => props.theme.error.color};
  background-color: ${(props) => props.theme.error.bgColor};
  border-radius: ${(props) => props.theme.border.radius};
  margin: ${(props) => props.theme.layout.padding} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.paddingMobile} 0;
  }

  pre {
    margin: 0;
    font-family: Consolas, Menlo, Courier, monospace;
    line-height: 1.8;
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

const ErrorReport = ({
  error,
  errorInfo,
  eventId,
}: {
  error: string | null
  errorInfo: ErrorInfo | null
  eventId: string | null
}) => {
  const dispatch = useAppDispatch()
  const { error: config } = useAppSelector(selectContent) || {}
  const { title: siteTitle } = useAppSelector(selectBrand) || {}

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleReset = () => {
    dispatch(resetOrderType())
    dispatch(resetCheckout())
    window.location.replace('/guest')
  }

  return (
    <>
      <Helmet>
        <title>
          {config?.title} | {siteTitle}
        </title>
      </Helmet>
      <Content hasRouter={false}>
        <Header title={<HeaderLogo />} />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            <PageTitle {...config} />
            <PageContent>
              <p>
                <Button onClick={handleReset}>Start Over</Button>
              </p>
              <ErrorReportView>
                {eventId && (
                  <p>
                    <ButtonLink
                      onClick={() => Sentry.showReportDialog({ eventId })}
                    >
                      Click here to report this issue
                    </ButtonLink>
                  </p>
                )}
                <ErrorReportDetails>
                  <pre>
                    {error?.toString()}
                    {errorInfo && errorInfo.componentStack?.toString()}
                  </pre>
                </ErrorReportDetails>
              </ErrorReportView>
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default ErrorReport
