import { TPLSPointsShopReward } from '@open-tender/types'
import { openModal, useAppDispatch } from '@open-tender/cloud'
import Heading from 'components/Heading'
import Text from 'components/Text'
import {
  PointsShopRewardButton,
  PointsShopRewardLocked,
  PointsShopRewardLockedText,
  PointsShopRewardPurchase,
} from './PointsShopReward.styled'
import { Lock } from 'components/icons'
import { Card } from 'components'

const PointsShopReward = ({
  reward,
  pointsBalance,
}: {
  reward: TPLSPointsShopReward
  pointsBalance?: number | null
}) => {
  const dispatch = useAppDispatch()
  const { name, description, image, points, is_available } = reward
  const bgStyle = image ? { backgroundImage: `url(${image}` } : undefined
  const isLocked =
    points && (pointsBalance || pointsBalance === 0)
      ? points > pointsBalance
      : false
  const disabled = isLocked || !is_available

  const onClick = () => {
    const args = { reward, pointsBalance }
    dispatch(openModal({ type: 'pointsShopReward', args }))
  }

  return (
    <PointsShopRewardButton onClick={onClick} disabled={disabled}>
      {isLocked && (
        <PointsShopRewardLocked>
          <span>
            <Lock size={22} strokeWidth={1.5} />
          </span>
          <PointsShopRewardLockedText>
            Unlock at {points} points
          </PointsShopRewardLockedText>
        </PointsShopRewardLocked>
      )}
      <Card bgStyle={bgStyle} title={name} subtitle={description}>
        <PointsShopRewardPurchase>
          <Heading as="p">{points} points</Heading>
          {!is_available && (
            <Text color="error" size="xSmall">
              Not Available
            </Text>
          )}
        </PointsShopRewardPurchase>
      </Card>
    </PointsShopRewardButton>
  )
}

export default PointsShopReward
