import { toggleNav, useAppDispatch } from '@open-tender/cloud'
import { Hamburger } from '../icons'
import Icon from './Icon'

const NavMenu = () => {
  const dispatch = useAppDispatch()

  return (
    <Icon
      aria-label="Navigation Menu"
      margin="0 -1.5rem 0 0"
      onClick={() => dispatch(toggleNav())}
    >
      <Hamburger size={21} />
    </Icon>
  )
}

export default NavMenu
