import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'
import { makeSlides } from '@open-tender/utils'
import { BackgroundLoading, Slider } from '../..'
import { AnnouncementSettings } from '@open-tender/types'
import { AnnouncementsState } from '@open-tender/cloud'

const AccountSliderView = styled.div`
  display: none;
  flex: 1;
  width: 100%;
  padding: 0 ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    min-height: 30rem;
    padding: 0;
  }

  & > div {
    overflow: hidden;
    // border-radius: ${(props) => props.theme.border.radius};
  }
`

const AccountSlider = ({
  announcements,
  style,
}: {
  announcements: AnnouncementsState & { settings: AnnouncementSettings }
  style?: React.CSSProperties
}) => {
  const { settings, entities, loading, error } = announcements || {}
  const slides = error ? null : makeSlides(entities, isMobile)
  const isLoading = loading === 'pending'

  if (!slides) return null

  return (
    <AccountSliderView style={style}>
      {isLoading ? (
        <BackgroundLoading />
      ) : slides ? (
        <Slider settings={settings} slides={slides} />
      ) : null}
    </AccountSliderView>
  )
}

export default AccountSlider
