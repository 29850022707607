import { isMobile } from 'react-device-detect'
import { ClipLoader } from 'react-spinners'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { useImage } from '@open-tender/utils'
import { CardImage } from 'components'
import { selectBrand, useAppSelector } from '@open-tender/cloud'

interface MenuItemImageViewProps {
  hidePadding?: boolean
}

const MenuItemImageView = styled.div<MenuItemImageViewProps>`
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
`

const MenuItemImageLoading = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MenuItemBackgroundImageContainer = styled.div`
  position: relative;
  flex: 1;
  // background-color: ${(props) => props.theme.bgColors.tertiary};
  padding: 2rem 3rem 0.5rem 2rem;
`

// const MenuItemBackgroundImage = styled(BgImage)`
//   position: absolute;
//   z-index: 2;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   opacity: 1;
//   animation: fade-in 0.25s ease-in-out 0s forwards;
//   background-size: ${(props) => props.theme.item.desktop.imageBgSize};
//   @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
//     background-size: ${(props) => props.theme.item.mobile.imageBgSize};
//   }
// `

const MenuItemImagePlaceholder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.bgColors.tertiary};

  img {
    height: 65%;
    width: auto;
  }
`

const MenuItemImage = ({
  imageUrl,
  alt,
}: {
  imageUrl: string | null
  alt: string
}) => {
  const { openGraphImage } = useAppSelector(selectBrand) || {}
  const { bgColors, item } = useTheme()
  const { desktop, mobile } = item
  const imageAsImg = isMobile ? mobile.imageAsImg : desktop.imageAsImg
  const { hasLoaded, hasError } = useImage(imageUrl)
  const isLoading = !hasLoaded && !hasError
  const bgStyle = imageUrl ? { backgroundImage: `url(${imageUrl}` } : undefined
  const showPlaceholder = !imageUrl && openGraphImage ? true : false

  return (
    <MenuItemImageView>
      {isLoading && (
        <MenuItemBackgroundImageContainer>
          <MenuItemImageLoading>
            <ClipLoader size={30} loading={true} color={bgColors.primary} />
          </MenuItemImageLoading>
        </MenuItemBackgroundImageContainer>
      )}
      {hasLoaded ? (
        <>
          {imageAsImg ? (
            <img src={imageUrl || undefined} alt={alt} />
          ) : (
            <MenuItemBackgroundImageContainer>
              {/* <MenuItemBackgroundImage style={bgStyle}>
                &nbsp;
              </MenuItemBackgroundImage> */}
              <CardImage bgStyle={bgStyle} />
            </MenuItemBackgroundImageContainer>
          )}
        </>
      ) : showPlaceholder ? (
        <MenuItemImagePlaceholder>
          <img src={openGraphImage || undefined} alt={alt} />
        </MenuItemImagePlaceholder>
      ) : null}
    </MenuItemImageView>
  )
}

export default MenuItemImage
