import { CardImageContainer, CardImageView, CardShadow } from './Card.styled'

const CardImage = ({ bgStyle }: { bgStyle?: React.CSSProperties }) => {
  return bgStyle ? (
    <CardImageContainer>
      <CardShadow />
      <CardImageView style={bgStyle} />
    </CardImageContainer>
  ) : null
}

export default CardImage
