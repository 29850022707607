const NavigationIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.99137 16.3074C1.99137 16.8973 2.46963 17.3756 3.05961 17.3756L16.3532 17.3756C16.9432 17.3756 17.4214 16.8973 17.4214 16.3074L17.4214 3.0138C17.4214 2.42382 16.9432 1.94556 16.3532 1.94556L3.0596 1.94556C2.46964 1.94556 1.99137 2.42382 1.99137 3.0138L1.99137 16.3074ZM7.04852 11.4792C6.81675 11.7109 6.81675 12.0867 7.04852 12.3185C7.28028 12.5502 7.65603 12.5502 7.8878 12.3185L11.3508 8.85538L11.3508 11.3393C11.3508 11.6671 11.6166 11.9328 11.9443 11.9328C12.2721 11.9328 12.5378 11.6671 12.5378 11.3393L12.5378 7.42268C12.5378 7.3422 12.5218 7.26545 12.4927 7.1955C12.4638 7.12547 12.4209 7.05995 12.3639 7.00306C12.307 6.94609 12.2415 6.9032 12.1715 6.87424C12.1015 6.8452 12.0248 6.82921 11.9443 6.82921L8.02768 6.82921C7.69991 6.82921 7.43421 7.09493 7.43421 7.42268C7.43421 7.75043 7.69991 8.01614 8.02768 8.01614L10.5116 8.01614L7.04852 11.4792Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default NavigationIcon
