import styled from '@emotion/styled'
import { BgImage } from 'components'

export const MenuItemImageView = styled.div`
  label: MenuItemImageView;
  position: relative;
  z-index: 1;
  top: -10px;
  left: -10px;
  flex: 1;
  line-height: 0;
  overflow: hidden;
  background-color: ${(props) => props.theme.menuItems.image.bgColor};
`

export const MenuItemImageLoading = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MenuItemBackgroundImage = styled(BgImage)`
  label: MenuItemBackgroundImage;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: ${(props) => props.theme.cards.menuItem.imageBgSize};
`

export const MenuItemImagePlaceholder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.bgColors.tertiary};

  img {
    height: 60%;
    width: auto;
  }
`
