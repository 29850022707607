import styled from '@emotion/styled'
import { BgImage, Body, Box } from 'components'

export const CardView = styled(Box)`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 2rem 3rem 2rem 2rem;
`

export const CardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const CardImageContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`

export const CardImageView = styled(BgImage)`
  position: relative;
  width: 100%;
  padding: 33.33333% 0;
  background-color: ${(props) => props.theme.bgColors.tertiary};
`

export const CardShadow = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.alert};
`

export const CardHeader = styled.div`
  flex-grow: 0;
`

export const CardContent = styled.div`
  flex-grow: 1;
`

export const CardTitle = styled(Body)`
  font-weight: ${(props) => props.theme.boldWeight};
  line-height: 120%;
`

export const CardSubtitle = styled(Body)`
  margin: 0.8rem 0 0;
`

export const CardFooter = styled.div`
  flex-grow: 0;
`
