import { Preface } from 'components'
import {
  CardContainer,
  CardContent,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardTitle,
  CardView,
} from './Card.styled'
import CardImage from './CardImage'
import { isMobile } from 'react-device-detect'

const Card = ({
  tag,
  preface,
  title,
  subtitle,
  children,
  footer,
  style,
  bgStyle,
}: {
  tag?: JSX.Element
  preface?: string
  title?: string
  subtitle?: React.ReactNode
  children?: React.ReactNode
  footer?: JSX.Element | string
  style?: React.CSSProperties
  bgStyle?: React.CSSProperties
}) => {
  return (
    <CardView style={style}>
      {tag}
      <CardContainer>
        {preface && (
          <CardHeader>
            <Preface>{preface}</Preface>
          </CardHeader>
        )}
        <CardImage bgStyle={bgStyle} />
        <CardContent>
          {title && (
            <CardTitle
              as="p"
              className="title"
              size={isMobile ? 'small' : 'main'}
            >
              {title}
            </CardTitle>
          )}
          {subtitle && (
            <CardSubtitle as="p" size={isMobile ? 'xSmall' : 'small'}>
              {subtitle}
            </CardSubtitle>
          )}
          {children}
        </CardContent>
        {footer && <CardFooter>{footer}</CardFooter>}
      </CardContainer>
    </CardView>
  )
}

export default Card
