import styled from '@emotion/styled'

interface SliderDotProps {
  active?: boolean
}

const SliderDot = styled.button<SliderDotProps>`
  width: 2rem;
  height: 0.6rem;
  border-radius: 1.5rem;
  background-color: ${(props) =>
    props.active ? props.theme.colors.dark : props.theme.colors.light};
`

export default SliderDot
