import styled from '@emotion/styled'

const SliderDots = styled.div`
  position: absolute;
  z-index: 13;
  bottom: 6rem;
  left: 0;
  right: 0;
  display: flex;
  justify-self: center;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.alert};
  padding: 1.2rem 2.5rem;
  border-radius: 6.3rem;
  gap: 0.6rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    bottom: 1.5rem;
    // padding: 0;
    // justify-content: center;
    // align-items: center;
  }
`

export default SliderDots
