import styled from '@emotion/styled'
import { Navigation } from 'react-feather'

export const AccountWelcomeView = styled.div`
  margin-left: 3rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 0 2rem;
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
  }
`

export const AccountWelcomeBackGreeting = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding-right: ${(props) => props.theme.layout.padding};
  gap: ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    padding: 0;
    gap: ${(props) => props.theme.layout.paddingMobile};
  }
`

export const AccountWelcomeBackBox = styled.div`
  background-color: ${(props) => props.theme.bgColors.light};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 36rem;
  max-width: 100%;
  padding: 2rem;
  box-shadow: ${(props) => props.theme.boxShadow.outer};
`

export const AccountWelcomeBackText = styled.p`
  line-height: 1;
  padding-bottom: 2rem;
  text-align: center;
`

export const AccountWelcomeBackNameText = styled.span`
  font-weight: ${(props) => props.theme.boldWeight};
`

export const AccountRewardsCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;

  button {
    position: relative;
    flex: 1;
    border-radius: ${(props) => props.theme.border.radius};
    padding-right: 0;
    padding-left: 0;
  }

  button span {
    flex-direction: column;
  }

  button:first-of-type:hover span {
    color: ${(props) => props.theme.buttons.colors.primaryHover.color};
  }

  button:last-of-type:hover span {
    color: ${(props) => props.theme.buttons.colors.secondaryHover.color};
  }
`

export const AccountRewardsNavigationIcon = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  align-self: flex-end;
`

export const AccountRewardsCard = styled.div<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: ${(props) =>
    props.theme.bgColors[props.backgroundColor ?? 'secondary']};
  padding: 10px;
  border-radius: 7.5px;
  width: 14rem;
  position: relative;
`

export const AccountWelcomeButton = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    position: relative;
    width: 100%;
    height: 100%;
    font-size: ${(props) => props.theme.fonts.sizes.xxBig};
    border-radius: ${(props) => props.theme.border.radius};
    box-shadow: ${(props) => props.theme.boxShadow.outer};
  }

  button svg {
    fill: ${(props) => props.theme.buttons.colors.primary.color};
  }

  button:hover svg {
    fill: ${(props) => props.theme.buttons.colors.primaryHover.color};
  }
`
export const AccountWelcomeButtonNavigationIcon = styled(Navigation)`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
`
