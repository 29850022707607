import styled from '@emotion/styled'
import Body from 'components/Body'

export const MenuItemTagsView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 ${(props) => props.theme.menuItems.tags.gap};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    gap: 0 ${(props) => props.theme.menuItems.tags.gapMobile};
  }
`

export const MenuItemTag = styled(Body)`
  transition: ${(props) => props.theme.menuItems.box.transition};
  letter-spacing: ${(props) => props.theme.menuItems.tags.letterSpacing};
  text-transform: ${(props) => props.theme.menuItems.tags.textTransform};
  color: ${(props) => props.theme.menuItems.tags.color};
  font-size: ${(props) => props.theme.menuItems.tags.fontSize};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.menuItems.tags.fontSizeMobile};
  }

  .show-hover:hover & {
    color: ${(props) => props.theme.menuItems.tags.colorHover};
  }
`

export const MenuItemTagImage = styled.div`
  display: flex;
  align-items: center;
  img {
    width: ${(props) => props.theme.menuItems.tags.width};
    margin: ${(props) => props.theme.tags.image.margin};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: ${(props) => props.theme.menuItems.tags.widthMobile};
    }
  }
`
