interface HamburgerProps {
  size: number
  strokeWidth?: number
}

const Hamburger = ({ size, strokeWidth, ...rest }: HamburgerProps & any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clipPath="url(#clip0_1383_233)">
        <path
          d="M18.0059 16.303V17.8001C18.0059 18.0052 17.9307 18.1829 17.7803 18.3333C17.6299 18.4837 17.4521 18.5589 17.2471 18.5589H0.758789C0.553711 18.5589 0.375977 18.4837 0.225586 18.3333C0.0751953 18.1829 0 18.0052 0 17.8001V16.303C0 16.098 0.0751953 15.9202 0.225586 15.7698C0.375977 15.6331 0.553711 15.5648 0.758789 15.5648H17.2471C17.4521 15.5648 17.6299 15.6331 17.7803 15.7698C17.9307 15.9202 18.0059 16.098 18.0059 16.303ZM18.0059 10.3148V11.8118C18.0059 12.0169 17.9307 12.1878 17.7803 12.3245C17.6299 12.4749 17.4521 12.5501 17.2471 12.5501H0.758789C0.553711 12.5501 0.375977 12.4749 0.225586 12.3245C0.0751953 12.1878 0 12.0169 0 11.8118V10.3148C0 10.1097 0.0751953 9.93195 0.225586 9.78156C0.375977 9.63116 0.553711 9.55597 0.758789 9.55597H17.2471C17.4521 9.55597 17.6299 9.63116 17.7803 9.78156C17.9307 9.93195 18.0059 10.1097 18.0059 10.3148ZM18.0059 4.30597V5.80304C18.0059 6.00812 17.9307 6.18585 17.7803 6.33624C17.6299 6.48663 17.4521 6.56183 17.2471 6.56183H0.758789C0.553711 6.56183 0.375977 6.48663 0.225586 6.33624C0.0751953 6.18585 0 6.00812 0 5.80304V4.30597C0 4.10089 0.0751953 3.92316 0.225586 3.77277C0.375977 3.62238 0.553711 3.54718 0.758789 3.54718H17.2471C17.4521 3.54718 17.6299 3.62238 17.7803 3.77277C17.9307 3.92316 18.0059 4.10089 18.0059 4.30597Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1383_233">
          <rect
            width="21.75"
            height="21"
            fill="currentColor"
            transform="matrix(1 0 0 -1 0 21.3685)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Hamburger
